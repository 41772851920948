<template>
  <!-- 勘探管理首页 -->
  <div class="firstPage bgblue">
    <!-- 头部模块· -->
    <div class="tip">
      <el-row :gutter="10">
        <el-col :span="6"
          ><div class="grid-content flex justify-between shadow">
            <div class="flex1">
              <img
                src="../../assets/images/expole_1.png"
                width="40"
                class="mt20"
              />
            </div>
            <div class="flex3 pb10">
              <div class="text mt10">
                <p>总有效勘探单量</p>
                <span class="span">{{obj.explorateNum}}</span>
              </div>
              <div class="text">
                <p>今日新增</p>
                <span class="span">{{obj.explorateToday}}</span>
              </div>
              <div class="text">
                <p>本月新增</p>
                <span class="span">{{obj.explorateMonth}}</span>
              </div>
              <div class="text pb10">
                <p>上月同期差异</p>
                <span class="span">{{obj.explorateDiff}}</span>
              </div>
            </div>
          </div></el-col
        >
        <el-col :span="6"
          ><div class="grid-content flex justify-between shadow">
            <div class="flex1">
              <img
                src="../../assets/images/expole_2.png"
                width="40"
                class="mt20"
              />
            </div>
            <div class="flex3 pb10">
              <div class="text mt10">
                <p>勘探编辑中</p>
                <span class="span">{{obj.explorateEdit}}</span>
              </div>
              <div class="text">
                <p>勘探已提交</p>
                <span class="span">{{obj.explorateCommit}}</span>
              </div>
              <div class="text">
                <p>勘探审批通过</p>
                <span class="span">{{obj.explorateAcess}}</span>
              </div>
              <div class="text">
                <p>勘探驳回</p>
                <span class="span" style="color: red">{{obj.explorateBack}}</span>
              </div>
            </div>
          </div></el-col
        >
        <el-col :span="6"
          ><div class="grid-content flex justify-between shadow">
            <div class="flex1">
              <img
                src="../../assets/images/expole_3.png"
                width="40"
                class="mt20"
              />
            </div>
            <div class="flex3 pb10">
              <div class="text mt10">
                <p>总有效安装单量</p>

                <span class="span">{{obj.installNum}}</span>
              </div>
              <div class="text">
                <p>今日新增</p>

                <span class="span">{{obj.installToday}}</span>
              </div>
              <div class="text">
                <p>本月新增</p>
                <span class="span">{{obj.installMonth}}</span>
              </div>
              <div class="text">
                <p>上月同期差异</p>

                <span class="span">{{obj.installDiff}}</span>
              </div>
            </div>
          </div></el-col
        >
        <el-col :span="6"
          ><div class="grid-content flex justify-between shadow">
            <div class="flex1">
              <img
                src="../../assets/images/expole_4.png"
                width="40"
                class="mt20"
              />
            </div>
            <div class="flex3 pb10">
              <div class="text mt10">
                <p>安装编辑中</p>
                <span class="span">{{obj.installDiff}}</span>
              </div>
              <div class="text">
                <p>安装已提交</p>
                <span class="span">{{obj.installDiff}}</span>
              </div>
              <div class="text">
                <p>安装复审通过</p>
                <span class="span">{{obj.installDiff}}</span>
              </div>
              <div class="text">
                <p>安装驳回</p>

                <span class="span">{{obj.installBack}}</span>
              </div>
            </div>
          </div></el-col
        >
      </el-row>
    </div>
    <!-- 中间模块 -->
    <div class="table">
      <el-row>
        <el-col :span="24">
          <div class="input">
            <span class="f12">业务类型：</span>
            <el-radio-group v-model="businessType" size="small">
              <el-radio label="1" border>勘探</el-radio>
              <el-radio label="2" border>安装</el-radio>
            </el-radio-group>
          </div>
          <div class="input ml20">
            <span class="f12">维度：</span>
            <el-radio-group v-model="dimension" size="small">
              <el-radio label="1" border>操作人员</el-radio>
              <el-radio label="2" border>时间</el-radio>
            </el-radio-group>
          </div>
          <div class="time ml20">
            <span class="f12">时间段：</span>
            <el-radio-group v-model="radio1" size="mini">
              <el-radio-button label="今日"></el-radio-button>
              <el-radio-button label="本月"></el-radio-button>
              <el-radio-button label="今年"></el-radio-button>
              <el-radio-button label="当年"></el-radio-button>
            </el-radio-group>
            <el-date-picker
              style="
                margin-left: 10px;
                vertical-align: middle;
                margin-right: 20px;
              "
              v-model="value1"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
          <el-button type="primary" icon="el-icon-search">查询</el-button>
        </el-col>
      </el-row>
    </div>
    <!-- 图表展示模块 -->
    <div class="pic">
      <div id="main" style="width: 1000px; height: calc(100vh - 380px)"></div>
      <div class="flex justify-between ml10 mr10">
        <div style="color: #aaaaaa" class="f14">
          柱状图为有效单据量;折线为功率KW;仅统计已提交和审核通过的有效单据
        </div>
        <div class="">
          <span style="font-weight: 800" class="f14">
            合计有效单量: 0 &nbsp;</span
          >
          <span style="font-weight: 800" class="f14"> 合计功率(KW): 0 </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from "echarts";
export default {
  data() {
    return {
      businessType: "1",
      dimension: "1",
      radio1: "今日",
      obj:{},
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      input: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      firstIndex: 0,
      value1: "",
      value2: "",
      value: "",
      currentPage4: 1,
    };
  },
  methods: {
    firstTab(val) {
      this.firstIndex = val;
    },
    handleSizeChange() {},
    handleCurrentChange() {},
    getData() {
      this.$http.post("/houseExplorateBase/queryAgentIndexInfo", {
        agentCode: JSON.parse(localStorage.getItem("userInfo")).userInfo.agentCode,
      }).then(res=>{
        this.obj = res.data.data
      });
    },
  },
  created(){
    this.getData()
  },
  mounted() {
    var myChart = echarts.init(document.getElementById("main"));

    // 指定图表的配置项和数据
    var option = {
      tooltip: {
        trigger: "axis",
        axisPointer: { type: "cross" },
      },

      legend: {
        data: ["合计有效单量", "合计功率(KW)"],
        textStyle: {
          fontSize: 16,
        },
      },
      xAxis: [
        {
          type: "category",
          axisTick: {
            alignWithLabel: true,
          },
          data: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月",
          ],
        },
      ],
      yAxis: [
        {
          type: "value",
          name: "合计有效单量",
          min: 0,
          max: 250,
          position: "right",
          axisLabel: {
            formatter: "{value}",
          },
        },
        {
          type: "value",

          name: "合计功率(KW)",
          min: 0,
          max: 25,
          position: "left",
          axisLabel: {
            formatter: "{value}KW ",
          },
        },
      ],
      series: [
        {
          name: "合计有效单量",
          type: "bar",
          width: 10,
          color: ["#2e64ee"],
          yAxisIndex: 0,
          data: [
            6, 32, 70, 86, 68.7, 100.7, 125.6, 112.2, 78.7, 48.8, 36.0, 19.3,
          ],
          barWidth: "50%",
        },
        {
          name: "合计功率(KW)",
          type: "line",
          smooth: true,
          yAxisIndex: 1,
          data: [
            6.0, 10.2, 10.3, 11.5, 10.3, 13.2, 14.3, 16.4, 18.0, 16.5, 12.0,
            5.2,
          ],
        },
      ],
    };
    // 使用刚指定的配置项和数据显示图表。
    myChart.setOption(option);
  },
};
</script>

<style lang="less">
.firstPage {
  .el-range-editor--small.el-input__inner {
    width: 250px;
  }
  padding: 10px 10px 1px 10px;
  margin-right: 5px;
  .el-radio--small.is-bordered {
    margin-right: 10px;
  }
  .active {
    border: 1px solid #409eff !important;
  }

  .tip {
    .el-col {
      border-radius: 4px;
    }
    .grid-content {
      border-radius: 4px;
      background-color: #fff;
      box-sizing: border-box;
      text-align: center;
      .text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        box-sizing: border-box;
        line-height: 2px;
      }

      i {
        border-radius: 50%;
        color: #609ef2;
        width: 40px;
        height: 40px;
        line-height: 40px;
        margin-top: 30px;
        text-align: center;
        background-color: #eff6fe;
      }
    }
    .row-bg {
      padding: 10px 0;
    }
    p {
      font-size: 14px;
      color: #a5a5a5;
    }
    .span {
      font-size: 16px;
      font-weight: 800;
      color: black;
      float: left;
      margin: 10px 10px;
    }
  }
  .table {
    padding: 10px;
    margin-top: 10px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 10px;
    .input {
      display: inline-block;
      // vertical-align: middle;
      button {
        border: 1px solid #dcdfe6;
        background-color: #fff;
        width: 80px;
        height: 40px;
        cursor: pointer;
        margin-left: 20px;
        border-radius: 5px;
      }
    }
    .time {
      display: inline-block;
      // .el-date-editor .el-input__icon {
      //   line-height: 21px !important;
      // }
      // .el-range-editor--small.el-input__inner {
      //   margin: 10px 25px;
      // }
    }
  }
  .pic {
    background-color: #fff;
    margin: 10px auto;
    padding-top: 30px;
    padding: 20px 0 10px 0;
    border-radius: 10px;
    button {
      border: 1px solid #dcdfe6;
      background-color: #fff;
      width: 80px;
      height: 40px;
      cursor: pointer;
      margin-left: 20px;
      border-radius: 5px;
    }
    #main {
      margin: 0 auto;
    }
  }
}
</style>
